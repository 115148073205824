<template>
  <base-section id="gallery">
    <v-container>
      <v-img
        width="100%"
        :src="require('@/assets/flowchart.svg')"
      ></v-img>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionGallery',
  }
</script>

<style lang="sass">
  #gallery
    padding: 0 0 !important
  #gallery .container
    padding: 0 0 !important
    max-width: 100% !important
  .gallery-card
    transform-origin: center center 0
    transition: .3s cubic-bezier(.25,.8,.50,1)
</style>
